import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const theme = {
    primary: '#b6cd3b',
    secondary: '#00A3E0',
    accent: '#b6cd3b',
    info: '#b6cd3b',
    colorMenu: '#363636'
}

export default new Vuetify({
    theme: {
        themes: {
            dark: theme,
            light: theme
        }
    }
})
