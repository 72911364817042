import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',

        drawer: null,

        auth: {
            loggedIn: false,
            token: '',
            comercial: ''
        },
        Fibra: '',
        LineasMovil: [],
        switchLineasMovil: '',
        switchFijo: '',
        codigoCoberturaFibra: '',
        carritoPedidosLineasMovil: '',
        jsonPedido: '',
        datosPortabilidadesValidados: '',
        datosPortabilidadesValidadosFijo: '',
        datosClienteValidados: '',
        total: '',
        fibraPreseleccionada: '',
        pedidoRealizado: false,
        comerciales: '',
        comercialesEditar: '',
        comercialesEliminar: '',
        comercialesInsertar: ''

    },
    mutations: {
        SET_DRAWER (state, payload) {
            state.drawer = payload
        },
        SET_SCRIM (state, payload) {
            state.barColor = payload
        },
        SET_TOKEN (state, token) {
            state.auth.token = token
        },
        SET_LOGGED_IN (state, estado) {
            state.auth.loggedIn = estado
        },
        SET_COMERCIAL (state, comercial) {
            state.auth.comercial = comercial
        },

        SET_FIBRA (state, Fibra) {
            state.Fibra = Fibra
        },
        SET_LINEASMOVIL (state, LineasMovil) {
            state.LineasMovil = LineasMovil
        },
        SET_SWITCHLINEASMOVIL (state, switchLineasMovil) {
            state.switchLineasMovil = switchLineasMovil
        },
        SET_SWITCHFIJO (state, switchFijo) {
            state.switchFijo = switchFijo
        },
        SET_CODIGOCOBERTURAFIBRA (state, codigoCoberturaFibra) {
            state.codigoCoberturaFibra = codigoCoberturaFibra
        },
        SET_CARRITOPEDIDOSLINEASMOVIL (state, carritoPedidosLineasMovil) {
            state.carritoPedidosLineasMovil = carritoPedidosLineasMovil
        },
        SET_JSONPEDIDO (state, jsonPedido) {
            state.jsonPedido = jsonPedido
        },
        SET_DATOSPORTABILIDADESVALIDADOS (state, datosPortabilidadesValidados) {
            state.datosPortabilidadesValidados = datosPortabilidadesValidados
        },
        SET_DATOSPORTABILIDADESVALIDADOSFIJO (state, datosPortabilidadesValidadosFijo) {
            state.datosPortabilidadesValidadosFijo = datosPortabilidadesValidadosFijo
        },

        SET_DATOSCLIENTEVALIDADOS (state, datosClienteValidados) {
            state.datosClienteValidados = datosClienteValidados
        },

        SET_TOTAL (state, total) {
            state.total = total
        },

        SET_FIBRAPRESELECCIONADA (state, fibraPreseleccionada) {
            state.fibraPreseleccionada = fibraPreseleccionada
        },
        SET_PEDIDOREALIZADO (state, pedidoRealizado) {
            state.pedidoRealizado = pedidoRealizado
        },
        SET_COMERCIALES (state, comerciales) {
            state.comerciales = comerciales
        },
        SET_COMERCIALES_INSERTAR (state, comercialesInsertar) {
            state.comercialesInsertar = comercialesInsertar
        },
        SET_COMERCIALES_EDITAR (state, comercialesEditar) {
            state.comercialesEditar = comercialesEditar
        },
        SET_COMERCIALES_ELIMINAR (state, comercialesEliminar) {
            state.comercialesEliminar = comercialesEliminar
        }
    },
    getters: {
        getToken (state) {
            return state.auth.token
        },
        isLoggedIn (state) {
            return state.auth.loggedIn
        },
        getFibra (state) {
            return state.Fibra
        },
        getComercial (state) {
            return state.auth.comercial
        },

        getLineasMovil (state) {
            return state.LineasMovil
        },
        getswitchLineasMovil (state) {
            return state.switchLineasMovil
        },
        getswitchFijo (state) {
            return state.switchFijo
        },
        getcodigoCoberturaFibra (state) {
            return state.codigoCoberturaFibra
        },
        getcarritoPedidosLineasMovil (state) {
            return state.carritoPedidosLineasMovil
        },
        getjsonPedido (state) {
            return state.jsonPedido
        },
        getdatosPortabilidadesValidados (state) {
            return state.datosPortabilidadesValidados
        },

        getdatosPortabilidadesValidadosFijo (state) {
            return state.datosPortabilidadesValidadosFijo
        },

        getdatosClienteValidados (state) {
            return state.datosClienteValidados
        },
        gettotal (state) {
            return state.total
        },
        getfibraPreseleccionada (state) {
            return state.fibraPreseleccionada
        },
        getpedidoRealizado (state) {
            return state.pedidoRealizado
        },
        getComerciales (state) {
            return state.comerciales
        },
        getComercialesInsertar (state) {
            return state.comercialesInsertar
        },
        getComercialesEditar (state) {
            return state.comercialesEditar
        },
        getComercialesEliminar (state) {
            return state.comercialesEliminar
        }
    },
    actions: {
        setToken (context, token) {
            context.commit('SET_TOKEN', token)
        },
        setComercial (context, comercial) {
            context.commit('SET_COMERCIAL', comercial)
        },

        setLoggedIn (context) {
            context.commit('SET_LOGGED_IN', true)
        },
        setLoggedOut (context) {
            context.commit('SET_LOGGED_IN', false)
        },
        setFibra (context, Fibra) {
            context.commit('SET_FIBRA', Fibra)
        },
        setLineasMovil (context, LineasMovil) {
            context.commit('SET_LINEASMOVIL', LineasMovil)
        },
        setswitchLineasMovil (context, switchLineasMovil) {
            context.commit('SET_SWITCHLINEASMOVIL', switchLineasMovil)
        },
        setswitchFijo (context, switchFijo) {
            context.commit('SET_SWITCHFIJO', switchFijo)
        },
        setcodigoCoberturaFibra (context, codigoCoberturaFibra) {
            context.commit('SET_CODIGOCOBERTURAFIBRA', codigoCoberturaFibra)
        },
        setcarritoPedidosLineasMovil (context, carritoPedidosLineasMovil) {
            context.commit('SET_CARRITOPEDIDOSLINEASMOVIL', carritoPedidosLineasMovil)
        },
        setjsonPedido (context, jsonPedido) {
            context.commit('SET_JSONPEDIDO', jsonPedido)
        },
        setdatosPortabilidadesValidados (context, datosPortabilidadesValidados) {
            context.commit('SET_DATOSPORTABILIDADESVALIDADOS', datosPortabilidadesValidados)
        },

        setdatosPortabilidadesValidadosFijo (context, datosPortabilidadesValidadosFijo) {
            context.commit('SET_DATOSPORTABILIDADESVALIDADOSFIJO', datosPortabilidadesValidadosFijo)
        },

        setdatosClienteValidados (context, datosClienteValidados) {
            context.commit('SET_DATOSCLIENTEVALIDADOS', datosClienteValidados)
        },
        settotal (context, total) {
            context.commit('SET_TOTAL', total)
        },
        setfibraPreseleccionada (context, fibraPreseleccionada) {
            context.commit('SET_FIBRAPRESELECCIONADA', fibraPreseleccionada)
        },
        setpedidoRealizado (context, pedidoRealizado) {
            context.commit('SET_PEDIDOREALIZADO', pedidoRealizado)
        },
        setComerciales (context, comerciales) {
            context.commit('SET_COMERCIALES', comerciales)
        },
        setComercialesInsertar (context, comercialesInsertar) {
            context.commit('SET_COMERCIALES_INSERTAR', comercialesInsertar)
        },
        setComercialesEditar (context, comercialesEditar) {
            context.commit('SET_COMERCIALES_EDITAR', comercialesEditar)
        },
        setComercialesEliminar (context, comercialesEliminar) {
            context.commit('SET_COMERCIALES_ELIMINAR', comercialesEliminar)
        }
    }

})
