import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('@/views/portada/Index'),
        children: [

            {
                name: 'login',
                path: '/',
                component: () => import('@/views/portada/login')
            }
        ]
    },
    {
        path: '/',
        component: () => import('@/views/navegacion/components/Index'),
        beforeEnter: guard,
        children: [

            {
                name: 'Crear pedido',
                path: '/crearPedido',
                component: () => import('./views/navegacion/configuradorPedido')
            },

            {
                name: 'comerciales',
                path: '/comerciales',
                component: () => import('./views/navegacion/comerciales')
            },
            {
                name: 'Ver pedido',
                path: '/verPedidos',
                component: () => import('./views/navegacion/verPedidos')
            }
        ]
    }

]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})

export default router

function guard (to, from, next) {
    const token = store.getters.getToken
    if (!token) {
        store.dispatch('setToken', '')
        store.dispatch('setComercial', '')
        next('/')
    } else {
        next()
    }
}
